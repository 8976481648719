// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"src/components/Location/index.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "src/components/Location/index.tsx");
  import.meta.hot.lastModified = "1730916919373.223";
}
// REMIX HMR END

import clsx from 'clsx';
export function Location({
  label,
  address
}) {
  const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(`${address.streetLine1}, ${address.city}, ${address.province}, ${address.country.name}`)}`;
  return <div className={clsx('border rounded-full w-80 min-w-80 h-20 px-4 py-6 my-2 flex flex-row justify-around items-center cursor-pointer overflow-hidden', 'bg-white text-white border-gray-400 dark:bg-slate-800 dark:text-slate-400 dark:border-slate-500')} onClick={() => window.open(googleMapsUrl, '_blank')}>
      <img src="./assets/google_location_icon.svg" />
      <div className="flex-1 flex flex-col justify-between items-start">
        <p className="w-60 text-md truncate text-black dark:text-slate-400">
          {label}
        </p>
        <p className="w-60 text-xs text-ellipsis overflow-hidden text-gray-400 dark:text-slate-300">{`${address.streetLine1} ${address.city}, ${address.province}, ${address.country.name}`}</p>
      </div>
    </div>;
}
_c = Location;
var _c;
$RefreshReg$(_c, "Location");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;